<template>
  <base-layout>
    <details-page-title
      :title="$t('components.orgUserDetailsManagement.header.title')"
      :details-id="id"
      @activate="handleActivating()"
      @deactivate="handleDeactivating()"
    >
      <button
        @click="showModal"
        v-if="isAgentUser"
        class="flex items-center justify-start w-40 px-2 py-2 text-sm text-orange-400 border border-gray-200 rounded-full shadow-lg focus:outline-none backround-white"
      >
        <img src="@/assets/img/icons/topup.svg" alt="" class="w-8 h-8 mr-2" />
        <span>{{
          $t('components.orgUserDetailsManagement.header.action.topUp')
        }}</span>
      </button>
    </details-page-title>

    <details-page-header
      v-if="isAgentUser"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :agentCardInfo="header.summary"
      :symbol="symbol"
      :tabs="agentTabs"
    />
    <details-page-header
      v-else-if="isFranchiseUser"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :franchiseCardInfo="header.summary"
      :symbol="symbol"
      :tabs="franchiseTabs"
    />
    <details-page-header
      v-else-if="isOperatorUser"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :operatorCardInfo="header.summary"
      :symbol="symbol"
      :tabs="operatorTabs"
    />
    <details-page-header
      v-else-if="isOwnerUser"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :tabs="userTabs"
    />
    <details-page-header
      v-else-if="isP2PUser"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :symbol="symbol"
      :tabs="p2pOwnerTabs"
    />
    <details-page-header
      v-else-if="isRentStationOwner"
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :symbol="symbol"
      :rentStationOwnerCardInfo="header.summary"
      :tabs="rentStationOwnerTabs"
    />

    <details-page-header
      v-else
      :name="getName"
      :badge="getBadge"
      :avatar="getAvatar"
      :email="getEmail"
      :phone="getPhone"
      :website="getWebsite"
      :showCreditInfo="true"
      :tabs="genericTabs"
    />
    <div class="min-h-screen">
      <router-view />
    </div>

    <t-modal v-model="topUpModal" class="topup-modal">
      <div class="p-8 modal-content">
        <h1
          class="text-xl font-semibold text-center text-gray-500 uppercase text-md"
        >
          {{
            $t(
              'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.transferTo'
            )
          }}
          <span class="text-gray-900">{{ header.full_name }}</span>
        </h1>
        <h1 class="font-bold text-center text-gray-900 uppercase text-md">
          {{ header.phone_number }}
        </h1>

        <div class="border border-gray-100"></div>

        <div class="flex items-end justify-center  mt-5">
          <div class="w-4/12   h-20">
            <p class="text-sm">
              {{
                $t(
                  'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.from'
                )
              }}
            </p>
            <div
              class="text-sm font-bold bg-gray-100 w-full rounded-md mt-2 p-2"
            >
              <p class="">
                {{ `${getDefaultCurrencyDetails.name}` }} ({{
                  `${getDefaultCurrencyDetails.symbol}`
                }})
              </p>
              <p>
                {{
                  $t(
                    'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.balance'
                  )
                }}
              </p>
            </div>
          </div>
          <div class="w-1/12  flex items-center justify-center mb-5">
            <i class="text-xl text-gray-500  fas fa-arrow-right"></i>
          </div>
          <div class="w-7/12  h-20">
            <p class="text-sm">
              {{
                $t(
                  'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.to'
                )
              }}
            </p>
            <div class=" bg-gray-100 w-full rounded-md mt-2 p-2">
              <t-rich-select
                :options="getWallets"
                valueAttribute="id"
                textAttribute="text"
                :placeholder="
                  $t(
                    'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.selectWallet'
                  )
                "
                v-model="targetAccount"
                hide-search-box
              />
            </div>
          </div>
        </div>
        <div class="mt-4 modal-input">
          <input
            type="text"
            v-model="balanceToTransfer"
            class="w-full h-16 px-3 text-2xl font-bold text-center text-orange-400 border rounded focus:outline-none"
            :placeholder="
              `e.g. ${
                selectedWallet ? selectedWallet.currency.symbol : symbol
              } 12.0`
            "
          />
          <h6 class="text-sm font-bold text-center text-gray-800">
            {{
              $t(
                'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.amountToReceive'
              )
            }}
          </h6>
        </div>

        <div v-if="!isSameCurrency">
          <div class="flex items-center justify-center mt-5 mb-4 space-x-4">
            <AppButton
              v-if="!exchangeRateData"
              :variant="`blue`"
              :text="
                $t(
                  'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.getRate'
                )
              "
              :isLoading="isBalanceConverting"
              @click="handleGetRate"
            />
            <div v-if="exchangeRateData" class="text-center">
              <p class="text-sm">
                {{
                  $t(
                    'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.amountToTransfer'
                  )
                }}:
                <span class="text-xl font-medium"
                  >{{ getDefaultCurrencyDetails.name }}
                  {{ exchangeRateData.converted_amount }}</span
                >
              </p>

              <p class="text-sm mt-5">
                {{
                  $t(
                    'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.currentExchangeRate'
                  )
                }}
              </p>
              <p class="text-lg font-medium">
                1 {{ selectedWallet.currency.name }} =
                {{ getDefaultCurrencyDetails.name }}
                {{ exchangeRateData.rate }}
              </p>
            </div>
          </div>
          <div
            v-if="exchangeRateData"
            class="flex items-center justify-end mt-16 mb-4 space-x-4"
          >
            <AppButton
              :variant="`secondary`"
              :text="$t('components.stepNavigation.cancel')"
              @click="handleAgentTopupCancel"
            />

            <AppButton
              variant="orange"
              :text="
                $t(
                  'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.transfer'
                )
              "
              :isLoading="isBalanceTransferring"
              @click="handleAgentTopup"
            />
          </div>
        </div>

        <div
          v-if="isSameCurrency"
          class="flex items-center justify-end mt-24 mb-4 space-x-4"
        >
          <AppButton
            :variant="`secondary`"
            :text="$t('components.stepNavigation.cancel')"
            @click="handleAgentTopupCancel"
          />

          <AppButton
            variant="orange"
            :text="
              $t(
                'components.orgUserDetailsManagement.diveIntoTab.profile.modals.topup.transfer'
              )
            "
            :isLoading="isBalanceTransferring"
            @click="handleAgentTopup"
          />
        </div>
      </div>
    </t-modal>
  </base-layout>
</template>

<script>
// import { StatusConfig } from '@/config/StatusConfig'
// import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'
import { AgentUserConfig } from '@/config/AgentUserConfig'
import BaseLayout from '@/views/shared/BaseLayout'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import DetailsPageHeader from '@/components/ui/DetailsPageHeader.vue'
// import StatusDropdown from "@/components/dropdown/StatusDropdown";
import { mapGetters } from 'vuex'
import xMan from '@/utils/xMan'
import { useEndpoints } from '@/composables'

export default {
  name: 'ViewOrganizationUser',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
  },
  data() {
    return {
      // Requset states
      reqLoading: false,
      reqLoaded: false,
      reqFailed: false,
      reqFailures: null,
      isBalanceTransferring: false,
      isBalanceConverting: false,
      exchangeRateData: null,
      symbol: '$',
      balanceToTransfer: null,
      org_user: {},
      fallbackText: '--',
      topUpModal: false,
      targetAccount: null,
      selectedWallet: null,
      header: {
        address_info: {
          current_address_country: '',
          current_address_full: '',
          current_address_zip_code: '',
          permanent_address_country: '',
          permanent_address_full: '',
          permanent_address_zip_code: '',
        },
        assigned_fleets: [],
        document_info: '',
        email: '',
        full_name: '',
        id: '',
        organization_id: '',
        payment_info: {
          account_name: '',
          account_number: '',
          branch_name: '',
          country: '',
          id: '',
          routing_number: '',
        },
        phone_number: '',
        profile: {
          birthday: '',
          gender: '',
          profile_pic: '',
          ranger_job_type: '',
        },
        role: {},
        user_status: '',
      },
      userTabs: [
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.profile'
          ),
          href: this.getTabHref(`profile`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.vehicles'
          ),
          href: this.getTabHref(`vehicles`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.payouts'
          ),
          href: this.getTabHref(`payouts`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.operationalAreas'
          ),
          href: this.getTabHref(`operational-areas`),
        },
        {
          text: this.$t('components.orgUserDetailsManagement.header.tab.tasks'),
          href: this.getTabHref(`tasks`),
        },
        {
          text: this.$t('components.orgUserDetailsManagement.header.tab.users'),
          href: this.getTabHref(`users`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.revenues'
          ),
          href: this.getTabHref(`revenues`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.riders'
          ),
          href: this.getTabHref(`riders`),
        },
      ],
      operatorTabs: [
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.profile'
          ),
          href: this.getTabHref(`profile`),
        },
        {
          text: this.$t('components.orgUserDetailsManagement.header.tab.tasks'),
          href: this.getTabHref(`tasks`),
        },
        {
          text: this.$t('components.orgUserDetailsManagement.header.tab.logs'),
          href: this.getTabHref(`logs`),
        },
      ],
      agentTabs: [
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.profile'
          ),
          href: this.getTabHref(`profile`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.topupHistory'
          ),
          href: this.getTabHref(`topup`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.incomingTransaction'
          ),
          href: this.getTabHref(`incoming`),
        },
      ],
      franchiseTabs: [
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.profile'
          ),
          href: this.getTabHref(`profile`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.fleets'
          ),
          href: this.getTabHref(`fleets`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.vehicles'
          ),
          href: this.getTabHref(`vehicles`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.riders'
          ),
          href: this.getTabHref(`riders`),
        },
        {
          text: this.$t('components.orgUserDetailsManagement.header.tab.rents'),
          href: this.getTabHref(`rents`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.longRents'
          ),
          href: this.getTabHref(`long-rents`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.revenues'
          ),
          href: this.getTabHref(`revenues`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.reports'
          ),
          href: this.getTabHref(`reports`),
        },
      ],
      p2pOwnerTabs: [
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.profile'
          ),
          href: this.getTabHref(`profile`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.vehicles'
          ),
          href: this.getTabHref(`p2p-owner-vehicles`),
        },
      ],
      rentStationOwnerTabs: [
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.profile'
          ),
          href: this.getTabHref(`profile`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.rentStation'
          ),
          href: this.getTabHref(`rent-stations`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.vehicles'
          ),
          href: this.getTabHref(`rent-stations-vehicles`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.riders'
          ),
          href: this.getTabHref(`rent-stations-riders`),
        },
        {
          text: this.$t('components.orgUserDetailsManagement.header.tab.rents'),
          href: this.getTabHref(`rent-stations-rents`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.revenues'
          ),
          href: this.getTabHref(`rent-stations-revenues`),
        },
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.reports'
          ),
          href: this.getTabHref(`rent-stations-reports`),
        },
      ],
      genericTabs: [
        {
          text: this.$t(
            'components.orgUserDetailsManagement.header.tab.profile'
          ),
          href: this.getTabHref(`profile`),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    isAgentUser() {
      return this.header.role.role_name == 'Agent' ? true : false
    },
    isFranchiseUser() {
      return this.header.role.role_name == 'Franchise' ? true : false
    },
    isOperatorUser() {
      return this.header.role.role_name == 'Operator' ? true : false
    },
    isOwnerUser() {
      return this.header.role.role_name == 'Owner' ? true : false
    },
    isP2PUser() {
      return this.header.role.role_name == 'P2P Owner' ? true : false
    },
    isRentStationOwner() {
      return this.header.role.role_name == 'Rent Station Owner' ? true : false
    },

    id() {
      return this.$route.params.id
    },
    getName() {
      return this.header ? this.header.full_name : ''
    },
    getBadge() {
      return this.header ? this.header.role.role_name : ''
    },
    getAvatar() {
      return this.header ? this.header.profile.profile_pic : ''
    },
    getEmail() {
      return this.header ? this.header.email : ''
    },
    getPhone() {
      return this.header ? this.header.phone_number : ''
    },
    getWebsite() {
      return this.header ? this.header.website_link : ''
    },
    getStatus() {
      return this.header && this.header.user_status === 'A' ? 'A' : 'D'
    },
    getDefaultCurrencyDetails() {
      return this.organizationInfo.default_currency || null
    },

    getWallets() {
      const wallets = this?.header?.financial_accounts
      return (
        wallets?.map((wallet, i) => {
          return {
            id: wallet.id,
            text: `Wallet ${i + 1} / ${wallet.currency.symbol}${
              wallet.balance
            } ${wallet.currency.name}`,
            balance: wallet.balance,
            currency: wallet.currency,
            default: wallet.default,
            fleets: wallet.fleets,
          }
        }) || []
      )
    },
    isSameCurrency() {
      if (
        this.selectedWallet &&
        this.getDefaultCurrencyDetails.id === this.selectedWallet.currency.id
      ) {
        return true
      }
      return false
    },
  },
  async created() {
    await this.loadUserData()
  },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'

    // todo: fix
    // this.$router.push({ name: 'ViewOrganizationUserProfile' })
  },
  watch: {
    getWallets(wallets) {
      const sw = wallets.find(
        (w) => w.currency.id === this.getDefaultCurrencyDetails.id
      )
      if (sw) {
        this.targetAccount = sw.id
      }
    },
    targetAccount: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          if (this?.header?.financial_accounts.length > 0) {
            this.selectedWallet = this.header.financial_accounts.find(
              (item) => item.id === val
            )
          }
        }
      },
    },
  },
  methods: {
    async loadUserData() {
      this.reqLoading = true
      await this.$http
        .get(this.$config.orgUser.api.single(this.id))
        .then((res) => {
          console.log('org-user-header', res)
          this.header = res.data

          this.reqLoaded = true
          this.reqLoading = false
        })
        .catch((err) => {
          console.log('org-user-header-err', err)
          this.reqFailures = err.data
          this.reqFailed = true
        })
        .finally(() => {
          this.reqLoading = false
        })
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/users/${this.$route.params.id}/${path}`
    },
    showModal() {
      return (this.topUpModal = !this.topUpModal)
    },
    handleAgentTopupCancel() {
      this.topUpModal = false

      //reset data
      this.isBalanceTransferring = false
      this.topUpModal = false
      this.balanceToTransfer = null
      this.exchangeRateData = null
    },
    handleGetRate() {
      var recharge_amount = this.balanceToTransfer
      if (isNaN(recharge_amount)) {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: 'Enter valid amount to convert',
          },
          5000
        )
        return
      }
      if (recharge_amount <= 0) {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: 'Amount must be greater than 0',
          },
          5000
        )
        return
      }
      this.isBalanceConverting = true
      const data = new xMan({
        from_currency_code: this.selectedWallet.currency.name,
        to_currency_code: this.getDefaultCurrencyDetails.name,
        amount_to_convert: parseFloat(recharge_amount),
      }).toFormData()
      this.$http
        .post(useEndpoints.user.agent.exchangeRate(), data)
        .then((res) => {
          console.log('exchange-rate-header', res.data)

          this.exchangeRateData = res.data
        })
        .catch((err) => {
          console.log('exchange-rate-err', err)
        })
        .finally(() => {
          this.isBalanceConverting = false
        })
    },
    async handleAgentTopup() {
      var recharge_amount = this.balanceToTransfer
      if (isNaN(recharge_amount)) {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: 'Enter valid amount to transact to agent',
          },
          5000
        )
        return
      }
      if (recharge_amount <= 0) {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: 'Amount must be greater than 0',
          },
          5000
        )
        return
      }
      // console.log("topup for user", this.header.id);
      const data = new xMan({
        recharge_amount: parseFloat(recharge_amount),
        financial_account: this.targetAccount,
      }).toFormData()
      this.isBalanceTransferring = true
      await this.$http
        .post(AgentUserConfig.api.balanceTransfer(this.header.id), data)
        .then((res) => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: res.data.details,
            },
            5000
          )

          this.loadUserData()
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isBalanceTransferring = false
          this.topUpModal = false
          this.balanceToTransfer = null
          this.targetAccount = null
          this.exchangeRateData = null
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
  width: 480px;
  height: 545px;
  background: #fff;
  margin: 0 auto;
  &-footer {
    padding-top: 20px;
    button {
      min-width: 100px;
      height: 35px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      width: auto;
      padding: 5px 10px;
      &.cancel {
        color: #6a6a6a;
      }
      &.confirm {
        color: #fff;
      }
    }
  }
}

::v-deep {
  .topup-modal {
    .max-w-3xl {
      max-width: 480px;
    }
  }
}
.divider {
  background: #e1e1e1;
  width: 1px;
  height: 100%;
  margin: 0 10px;
}
</style>
